import { createRouter, createWebHistory } from "vue-router";
// import Home from "../views/Home.vue";

const routes = [
  {
    path: '/',
    redirect: '/demo/amount'
  },
  {
    path: '/demo',
    redirect: '/demo/amount',
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: (to) => {
      return `${to.path}/amount`
    }
  },
  /* {
    path: '/:path/amount',
    component: 'AmountView'
  }, */
  {
    name: "AmountView",
    path: "/:org/amount",
    component: () => import("../views/Amount.vue")
  },
  {
    name: "IdentifyView",
    path: "/:org/identify",
    component: () => import("../views/Identify.vue")
  },
  {
    name: "PayView",
    path: "/:org/pay",
    component: () => import("../views/Pay.vue")
  },
  {
    name: "ReceiptView",
    path: "/:org/receipt",
    component: () => import("../views/Receipt.vue")
  },
  {
    name: "404",
    path: "/:org/404",
    /* redirect: (to) => {
      return `${to.path}/404`
    } */
    component: () => import("../views/404.vue")
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
