<template>
  <!-- <div id="nav"> -->
    <!-- <router-link to="/">Home</router-link> | -->
    <!-- <router-link to="/payments">Payments</router-link> | -->
    <!-- <router-link to="/about">About</router-link> -->
    <!-- <router-link to="/identify">Identify</router-link>
    <router-link to="/amount">Amount</router-link>
    <router-link to="/pay-new-card">Pay - New Card</router-link> -->
    <!-- &bull; -->
  <!-- </div> -->

  <router-view />

  <div id="loader-wrapper">
    <div class="loader is-loading"></div>
  </div>

  <div id="lang" class="has-text-centered">
    <a v-on:click="setLocale('en')">
      {{ $t('lang.english') }}
    </a>
    <!-- <span> | </span>
    <a v-on:click="this.$i18n.locale = 'empty'">
      {{ $t('lang.empty') }}
    </a> -->
    <span> | </span>
    <a v-on:click="setLocale('es')">
      {{ $t('lang.spanish') }}
    </a>
  </div>
</template>

<script>
  // import { getCurrentInstance } from 'vue';
  import { version, watch, computed } from 'vue';
  import { useRouter, useRoute } from 'vue-router';
  import ApiService from '@/services/ApiService';

  export default {
    data() {
      return {
        org: {},
      }
    },
    provide() {
      return {
        //-- application level config --//
        APP_PROPS: {
          ENV: process.env.NODE_ENV,
          MODE: process.env.VUE_APP_MODE,
          VUE_VERSION: version,
          APP_VERSION: process.env.VUE_APP_APP_VERSION,
        },
        org: computed(() => this.org),
      }
    },
    created() {
      console.info("App - created()", this);

      const router = useRouter();
      const route = useRoute();
      
      console.info("router:", router, "route:", route);

      //-- determine the org name from the path org key --//
      watch(
        () => route.params.org,
        async orgKey => {
          console.info(`orgKey is '${orgKey}'`);
          // alert("org:", org);
          import('/public/css/default.css');
          if(orgKey !== "demo") {
            import(`/public/css/${orgKey}.css`)
              .catch(function() {
                console.error("CSS import failed :(");
                // router.push({name: '404'});
                // return false;
                // import('/public/css/default.css')
              })
            ;
          }

          try {

            let desQuery = {
              "where": {
                "key": orgKey
              }
            };

            ApiService.get('organizations', desQuery)
              .then((response) => {
                console.log("orgs GET full response", response);
                console.log("orgs GET successful!", response.data);

                if(response.data.length === 0) throw new Error('No organization with that key found');

                localStorage.org = JSON.stringify(response.data[0]);
                this.org = response.data[0];
              })
              .catch((error) => {
                console.error("'organizations' GET unsuccessful.", error);
              })
            ;

          } catch(err) {
            console.error("error detecting org.", err);
            router.push({name: '404'});
            // return false;
          }
        }
      );

    },
    mounted() {
      console.info("App - mounted()");
      if(localStorage.getItem('locale')) {
        this.$i18n.locale = localStorage.getItem('locale');
      }

      console.info("App.vue - route = ", this.$route.params);

    },
    watch: {
      
    },
    methods: {
      setLocale(locale) {
        this.$i18n.locale = locale;
        localStorage.setItem('locale', locale);
      }
    }
  }
</script>

<style>
  /* CSS that should be displayed if width is equal to or less than 800px goes here */
  @media (max-width: 1120px) {
    #app .column.is-offset-one-fifth, .column.is-offset-one-fifth-tablet {
      margin-left: 0;
    }
    #app .column.is-three-fifths, .column.is-three-fifths-tablet {
      flex: none;
      width: 100%;
    }
  }
  /* #nav {
    display: none;
  } */

  .columns {
    clear: both;
  }

  #app {
    /* font-family: Avenir, Helvetica, Arial, sans-serif; */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
    overflow: auto;
  }

  /*
  #nav {
    padding: 30px;
    text-align: center;
    background-color: #0da49b;
  }

  #nav a {
    font-weight: bold;
    color: #2c3e50;
  }

  #nav a {
    padding: 0 7px;
  }

  #nav a.router-link-exact-active {
    color: #42b983;
  }
  */

  #loader-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: #dedede;
    /* opacity: 0; */
    /* z-index: -1; */
    transition: opacity .3s;
    display: none;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
  }

  #loader-wrapper.is-active {
    opacity: 0.9;
    z-index: 999;
    display: flex;
  }

  #loader-wrapper .loader {
    height: 80px;
    width: 80px;
    border-width: 3px;
    border-color: #dedede #ffffff;
  }

  #main-columns {
    margin-left: 0;
    margin-right: 0;
  }

  #header {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px 25px 25px 20px;
  }

  #header #nav-left {
    position: absolute;
    left: 10%;
    font-size: 15px;
  }

  #header #nav-right {
    position: absolute;
    right: 10%;
    font-size: 15px;
  }

  #lang {
    margin: 1.5em 0;
  }

  #lang a {
    margin: 0 0.5em;
  }

</style>
