import ApiService from '@/services/ApiService';
import jwtDecode from 'jwt-decode';

export default {

  authenticate: function() {
    console.info("authenticate()");

    const promise = new Promise( (resolve, reject) => {

      let jwt = localStorage['jwt'] != undefined ? JSON.parse(localStorage['jwt']) : undefined;

      if(jwt && jwt['accessToken']) {
        this.checkJwtExp(jwt['accessToken'])
          .then(() => {
            ApiService.updateToken( jwt['accessToken'] );
            resolve();
          })
          .catch((error) => {
            reject(error);
          })
        ;
      } else {
        this.acquireJwt()
          .then(() => {
            resolve();
          })
          .catch((error) => {
            reject(error);
          })
        ;
      }

    });

    return promise;
  },

  checkJwtExp: function(accessToken) {
    console.info("checkJwtExp() - accessToken:", accessToken);

    const promise = new Promise( (resolve, reject) => {
      try {
        const decodedJwt = jwtDecode(accessToken);
        console.info("decodedJwt", decodedJwt);

        const exp = decodedJwt['exp'];
        const now = Math.floor(new Date().getTime() / 1000);
        console.info(`exp: ${exp}, now: ${now}`);

        if(exp > now) {
          console.info("Token is current; let's proceed");
          return resolve();
        } else {
          console.info("Token expired; toss it and get a new one first");
          this.clearJwt();
          this.acquireJwt()
            .then(() => {
              resolve();
            })
            .catch((error) => {
              reject(error);
            })
          ;
        }

      } catch(error) {
        console.error("Error decoding JWT:", error);
        reject(error);
      }
    });

    return promise;

  },

  acquireJwt: function() {
    console.info("acquireJwt()");

    const promise = new Promise( (resolve, reject) => {
      let fbUser;

      try {
        console.info("localStorage['fb_user'] = ", localStorage['fb_user']);
        fbUser = JSON.parse( localStorage['fb_user'] );
      } catch(error) {
        console.error("error parsing fb_user JSON", error);
        reject(error);
      }

      const payload = {
        token: fbUser['accessToken']
      };

      ApiService.validate(payload)
        .then(response => {
          console.log("successful 'users/validate' POST:", response.data);

          const jwt = response?.data?.token;
          if(jwt) {
            localStorage['jwt'] = JSON.stringify(jwt);
            console.info("ApiService", ApiService);
            ApiService.updateToken( jwt['accessToken'] );

            resolve();
          } else {
            throw {"No JWT present": response};
          }
        })
        .catch(error => {
          console.error("'users/validate' unsuccessful", error);
          reject(error);
        })
      ;

    });

    return promise;

  },

  clearJwt() {
    localStorage.removeItem('jwt');
  }

}