export default {
  "lang": {
    "english": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingles"])},
    "spanish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Español"])},
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'Empty'"])}
  },
  "profile": {
    "signout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerrar Sesión"])}
  },
  "designation": {
    "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccione designación..."])},
    "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otro"])},
    "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quite Designación"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+ Añada Designación"])},
    "amount": {
      "invalid": {
        "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Favor de escoger una cantidad para donar por esta designación"])}
      }
    },
    "invalid": {
      "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Favor de escoger una designación"])}
    }
  },
  "amount": {
    "nav": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escoja Cantidad"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diezmos y Ofrendas"])},
    "recurrence": {
      "once": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Una Vez"])},
      "recur": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recurrente"])},
      "freq": {
        "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecciones la frecuencia..."])},
        "daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cada día"])},
        "weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semanalmente"])},
        "bi-weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cada Dos Semanas"])},
        "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mensualmente"])},
        "invalid": {
          "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Debe seleccionar la frecuencia para los pagos recurrentes"])}
        }
      }
    },
    "note": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nota"])}
    },
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Próximo"])},
    "form": {
      "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Favor de hacer correcciones para continuar"])}
    }
  },
  "identify": {
    "logged_in": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Usuario% ", _interpolate(_named("email")), " actualmente en uso"])},
    "fb_auth_error": {
      "weak_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Favor de entrar contraseña por lo menos 6 caracteres"])},
      "invalid_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Favor de entrar correo electrónico válido"])},
      "wrong_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contraseña incorrecta"])},
      "user_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No usuario registrado con este correo, favor de registrarse o usar una cuenta diferente"])},
      "email_in_use": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este correo está en uso por otra cuenta."])}
    },
    "login": {
      "nav": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información"])},
      "email": {
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección de Correo Electrónico"])},
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este correo electrónico no es válido"])}
      },
      "password": {
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contraseña"])},
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este contraseña no es válido"])},
        "forgot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Se le olvidó la contraseña?"])},
        "reset": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reinicie la contraseña"])},
          "instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entre correo electrónico de su cuenta, y le enviaremos un correo con un enlace para reiniciar la contraseña."])},
          "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le enviamos instrucciones a su correo electrónico para reiniciar la contraseña. Vaya a su correo electrónico para proseguir."])},
          "failure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desafortunadamente no podemos enviar instrucciones para reiniciar la contraseña al correo electrónico que usted ha provisto. Favor de ver su buzón de correo."])},
          "failure_notfound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No tenemos una cuenta asociada con el correo electrónico que nos ha provisto. Favor de verificar que esta correcto y trate de nuevo, o crea una cuenta."])},
          "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Favor de entrar una dirección electrónica válida"])},
          "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aceptar"])}
        }
      },
      "signin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conéctate"])}
    },
    "register": {
      "nav": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cree una Cuenta"])},
      "prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿No tiene una cuenta?"])},
      "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cree una Cuenta"])},
      "fname": {
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Primer Nombre"])},
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Favor de entrar un valor por el primer nombre"])}
      },
      "lname": {
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Apellido"])},
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Favor de entrar un valor por el apellido"])}
      },
      "email": {
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Dirección Correo Electrónico"])},
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Favor de entrar un valor por el dirección correo electrónico"])}
      },
      "pass": {
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Contraseña (mínimo de 6 caracteres)"])},
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Favor entrar contraseña (mínimo 6 caracteres)"])}
      },
      "pass_confirm": {
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Confirme Contraseña"])},
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Favor de repetir la contraseña"])}
      },
      "pass_confirm_mismatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La contraseña y su confirmación no combinan"])},
      "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Próximo"])}
    },
    "identified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identificado"])},
    "guest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continúe como invitado"])}
  },
  "pay": {
    "nav": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pago"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haga Pago"])},
    "new_pay_meth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use otro método de pago"])},
    "method": {
      "credit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarjeta de Crédito"])},
      "checking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuenta de Cheques"])}
    },
    "fname": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primer Nombre"])},
      "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Favor de entrar valor por primer nombre"])}
    },
    "lname": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apellido"])},
      "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Favor de entrar valor por apellido"])}
    },
    "zip": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zona Postal"])},
      "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zona Postal de 5 dígitos es requerida"])}
    },
    "cc": {
      "acc_num": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de la Tarjeta"])},
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de la tarjeta es requerido"])}
      },
      "exp": {
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de Expiración"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de Expiración"])},
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de expiración es requerida"])}
      },
      "cvc": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código CVC"])},
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código de tarjeta de crédito es requerido"])}
      }
    },
    "ach": {
      "acc_num": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de Cuenta"])},
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de cuenta es requerido"])}
      },
      "acc_num_2": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirme Númeo de Cuenta"])},
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Favor de repetir número de cuenta para confirmar"])}
      },
      "rout_num": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de Enrutamiento"])},
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de enrutamiento es requerido"])}
      }
    },
    "save_meth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guarde el método de pago para usos futuros"])},
    "contrib_a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Me gustaría contribuir"])},
    "contrib_b": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["para ayudar con los gastos de esta transacción"])},
    "donate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donar"])},
    "t&c": {
      "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Términos y Condiciones"])},
      "verbiage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al pulsar 'Done' usted está aprobando lo siguiente"])},
      "policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al pulsar el botón de 'comprar' o 'donar' para iniciar el pago, usted autoriza The Giving Solution a debitar la cuenta de banco especificada arriba for cualquier cantidad adeudada por los cargos derivados del uso de los servicioes de The Giving Solution LLC y/o la compra de productos y/o servicios de The Giving Solution, de acuerdo a la página WEB y los términos de The Giving Solution, hasta que esta autorización sea revocada. Usted puede enemendar o cancelar esta autorización en cualquier momento proveyendo un aviso a The Giving Solution con treinta (30) días de anticipación. Si usted usa los servicios de The Giving Solution o compra productos adicionales periódicamente de acuerdo con los términos de The Giving Solution, usted autoriza The Giving Solution a cargar su cuenta de banco periódicamente. Los pagos que caigan fuera de los cargos regulares autorizados arriba solamente serán debitados después de que sea obtenida su autorización."])}
    },
    "donation_err": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donación Exitosa"])},
      "msg_a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su donación no fue recibida exitosamente"])},
      "msg_b": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Favor de corregir cualquier error y/o trate más tarde"])}
    }
  },
  "receipt": {
    "nav": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recibo"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pago Recibido"])},
    "thanks_a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gracias por su donativo de"])},
    "thanks_b": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["para"])},
    "thanks_c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Señal confirmando su donativo, para sus archivos es"])},
    "enter_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Favor dar su correo electrónico si desea enviemos recibo."])},
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cargando"])},
    "email": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección Correo Electrónico"])},
      "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este es un campo requerido"])},
      "success": {
        "result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Correo electrónico enviado!"])},
        "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Este recibo fue enviado exitosamente a <em>", _interpolate(_named("email")), "</em>"])}
      },
      "failure": {
        "result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correo electrónico no enviado"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su donación fue completada con éxito, sin embargo, no pudimos enviarle este recibo"])}
      }
    },
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evíe"])}
  }
}