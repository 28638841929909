export default {
  "lang": {
    "english": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
    "spanish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spanish"])},
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'Empty'"])}
  },
  "profile": {
    "signout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign Out"])}
  },
  "designation": {
    "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a designation..."])},
    "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other"])},
    "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove Designation"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+ Add Designation"])},
    "amount": {
      "invalid": {
        "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select a donation amount for this designation"])}
      }
    },
    "invalid": {
      "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select a designation"])}
    }
  },
  "amount": {
    "nav": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose Amount"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tithes and Offerings"])},
    "recurrence": {
      "once": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One-time"])},
      "recur": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recurring"])},
      "freq": {
        "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a frequency..."])},
        "daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daily"])},
        "weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weekly"])},
        "bi-weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bi-weekly"])},
        "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monthly"])},
        "invalid": {
          "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A frequency must be selected for recurring payments"])}
        }
      }
    },
    "note": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note"])}
    },
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
    "form": {
      "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please make corrections to continue"])}
    }
  },
  "identify": {
    "logged_in": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["User ", _interpolate(_named("email")), " currently logged in"])},
    "fb_auth_error": {
      "weak_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter password at least 6 characters in length"])},
      "invalid_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a valid email address"])},
      "wrong_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wrong Password"])},
      "user_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No user registered with this email address, please register or use a different account"])},
      "email_in_use": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The email address is already in use by another account."])}
    },
    "login": {
      "nav": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information"])},
      "email": {
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Address"])},
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This email is invalid"])}
      },
      "password": {
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This password is invalid"])},
        "forgot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot password?"])},
        "reset": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset Password"])},
          "instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the email address for your account, and we will send you an email with a link to reset your password"])},
          "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We have emailed password reset instructions to the email address provided. Please check your email account to proceed."])},
          "failure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unfortunately we were unable to send password reset instructions to the email address you provided. Please check the email"])},
          "failure_notfound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We do not have an account associated with the email address you have provided. Please verify it's correct and try again, or create a new account."])},
          "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a valid email address"])},
          "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OK"])}
        }
      },
      "signin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign In"])}
    },
    "register": {
      "nav": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Account"])},
      "prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Don't have an account?"])},
      "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Account"])},
      "fname": {
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*First Name"])},
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a value for first name"])}
      },
      "lname": {
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Last Name"])},
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a value for last name"])}
      },
      "email": {
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Email Address"])},
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a value for email address"])}
      },
      "pass": {
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Password (6 character minimum)"])},
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a password at least 6 characters in length"])}
      },
      "pass_confirm": {
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Confirm Password"])},
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please re-enter the same password as above"])}
      },
      "pass_confirm_mismatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The password and password confirmation do not match"])},
      "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])}
    },
    "identified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identified"])},
    "guest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue as a guest"])}
  },
  "pay": {
    "nav": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay"])},
    "new_pay_meth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use a new payment method"])},
    "method": {
      "credit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Credit Card"])},
      "checking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Checking Account"])}
    },
    "fname": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Name"])},
      "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a value for first name"])}
    },
    "lname": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Name"])},
      "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a value for last name"])}
    },
    "zip": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zip Code"])},
      "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5-digit zip code is required"])}
    },
    "cc": {
      "acc_num": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Card Number"])},
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Credit card number is required"])}
      },
      "exp": {
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exp. Date"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expiration Date"])},
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Credit card expiration date is required"])}
      },
      "cvc": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CVC Code"])},
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Credit card security code is required"])}
      }
    },
    "ach": {
      "acc_num": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account Number"])},
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account number is required"])}
      },
      "acc_num_2": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm Account Number"])},
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please re-enter the account number to confirm"])}
      },
      "rout_num": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Routing Number"])},
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Routing number is required"])}
      }
    },
    "save_meth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save this payment method for future use"])},
    "contrib_a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I'd like to contribute"])},
    "contrib_b": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to help cover the administrative costs of this transaction"])},
    "donate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donate"])},
    "t&c": {
      "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms & Conditions"])},
      "verbiage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By clicking the 'Donate' button you are agreeing to the following"])},
      "policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By clicking the buy or donate button to initiate a payment, you authorize The Giving Solution to debit the bank account specified above for any amount owed for charges arising from your use of The Giving Solution LLC's services and/or purchase of products and/or services from The Giving Solution, pursuant to The Giving Solution's website and terms, until this authorization is revoked. You may amend or cancel this authorization at any time by providing notice to The Giving Solution with 30 (thirty) days notice.  If you use The Giving Solution's services or purchase additional products periodically pursuant to The Giving Solution's terms, you authorize The Giving Solution to debit your bank account periodically. Payments that fall outside of the regular debits authorized above will only be debited after your authorization is obtained."])}
    },
    "donation_err": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donation Unsuccessful"])},
      "msg_a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your donation was not received successfully."])},
      "msg_b": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please correct any mistakes and/or try again later."])}
    }
  },
  "receipt": {
    "nav": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receipt"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Received"])},
    "thanks_a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you for your donation of"])},
    "thanks_b": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to"])},
    "thanks_c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The confirmation ID for your records of this donation is"])},
    "enter_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your email address below if you would like to receive this donation receipt as an email."])},
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading"])},
    "email": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Address"])},
      "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field is required"])},
      "success": {
        "result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Sent!"])},
        "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["This receipt was successfully sent to <em>", _interpolate(_named("email")), "</em>"])}
      },
      "failure": {
        "result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Not Sent"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your donation completed successfully, however we were unable to email you this receipt"])}
      }
    },
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])}
  }
}